import { GracePeriodValues } from 'src/utils/constants/distributors';
import momentLocalTimezone from 'src/utils/momentLocalTimezone';

export default values => ({
  ...values,
  startDate: momentLocalTimezone(),
  firstRentDate: values.enableMsi
    ? momentLocalTimezone()
    : momentLocalTimezone().add(1, 'month'),
  gracePeriod: values.enableMsi
    ? GracePeriodValues.SAME_DAY_AS_CONTRACT_STARTS
    : GracePeriodValues.ZERO,
  endDate: momentLocalTimezone().add(values.term, 'months'),
  payday: Math.min(momentLocalTimezone().date(), 25)
});
