import momentLocalTimezone from 'src/utils/momentLocalTimezone';

export default values => ({
  ...values,
  startDate: momentLocalTimezone(),
  firstRentDate: momentLocalTimezone().add(1, 'month'),
  gracePeriod: values.gracePeriod || 0,
  endDate: momentLocalTimezone().add(values.term, 'months'),
  payday: Math.min(momentLocalTimezone().date(), 25)
});
